import { Attribute, Im, Ka, Kp, Li, Tb } from "types";
import { Button } from "components/button";
import { Card } from "components/card";
import { Cloudinary } from "components/cloudinary";
import { Col, Row } from "react-bootstrap";
import { colors } from "theme/theme";
import { ContentSet } from "components/contentset";
import { entitiesConfig } from "config/entity";
import { FunctionComponent, useEffect, useState } from "react";
import { Lightbox } from "components/lightbox";
import { makeParagraph, sanitize, unescape } from "helpers/text-processing";
import { RelatedProductsSlider } from "components/slider/related-product-slider";
import { Shape } from "components/shape";
import { Slider } from "components/slider";
import { useLabels } from "helpers/hooks";
import { useLocale } from "helpers/locale";

import {
  CloudinaryProps,
  DeepPartial,
  ProductDetailDownloadProps,
  ProductDetailProps,
  ResolvedUrl,
} from "constants/types";
import { Tabs } from "components/tabs";

const DiscontinuedWrapper = ({ recommended_successor_kp, tb, eye_catcher }) => {
  let renderedTb = tb.find((child) => child.id === "tb-4");
  if (recommended_successor_kp?.length > 0) {
    if (eye_catcher[0]?.code === "superseded") {
      renderedTb = tb.find((child) => child.id === "tb-69");
    }
  } else {
    renderedTb = tb.find((child) => child.id === "tb-9");
  }
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: makeParagraph(renderedTb.text_body as string),
        }}
      ></div>
      <RelatedProductsSlider related={recommended_successor_kp} />
    </>
  );
};

export const RelatedImageSlide = ({ image }: { image: Im }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div>
        <div className="fullscreen-slider-icon" onClick={() => setOpen(true)}>
          <Shape variant="fullscreen" fill={colors.blue} />
        </div>
        <Cloudinary media={image} responsiveOrientation="height" />
      </div>
      <Lightbox
        heading={{
          title: image.title,
          stylingLevel: "h4",
        }}
        headingType="primary"
        background="grey-triangle"
        size="large"
        open={open}
        setOpen={setOpen}
      >
        <Cloudinary media={image} responsiveOrientation="height" />
      </Lightbox>
    </>
  );
};

export const ProductOverview: FunctionComponent<
  Partial<Kp> & {
    attributes?: Attribute[];
    tb?: Tb[];
    variant?: "so" | null;
  }
> = ({
  attributes = [],
  product_description,
  typical_applications,
  product_highlights_detail,
  recommended_successor_kp,
  is_discontinued,
  eye_catcher,
  related_images,
  tb,
  variant,
}) => {
  const [showMoreButtonAction] = useLabels(["ui-107", "show more"]);
  const [productHightlightLabel] = useLabels([
    "ui-901",
    "Product features and options depend on device configuration: Contact us to make sure that your preferred functionality can be combined in one device.",
  ]);

  // typical_applications is missing on de?
  const overviewHighlightsLabel = attributes?.find(
    (att) => att.code === "overview_highlights",
  );
  const productHighlightsLabel = attributes?.find(
    (att) => att.code === "product_highlights_detail",
  );
  const typicalApplicationsLabel = attributes?.find(
    (att) => att.code === "typical_applications",
  );

  /* make p tag and remove line breaks */
  const ProductDescriptionHTMLContent = makeParagraph(
    product_description,
  ).replace(/\r?\n|\r/g, "");

  /* Check if show more btn is in product_description */
  const regex = /(<button class="expand-hidden-content">)(.*)(<\/button>)(.*)/;
  const buttonRegex = /(?=<button class="expand-hidden-content">).*\/button>/;

  const hiddenPartReg = regex.exec(ProductDescriptionHTMLContent);

  const hiddenPart = hiddenPartReg ? hiddenPartReg?.[4] : "";

  const button = buttonRegex.exec(ProductDescriptionHTMLContent);
  const replacedDescriptionText = ProductDescriptionHTMLContent.replace(
    hiddenPart,
    "",
  ).replace(button ? button[0] : "", "");

  const [productDescription, setProductDescription] = useState(
    replacedDescriptionText,
  );

  const [buttonVisibility, setButtonVisibility] = useState(true);
  const [
    buttonVisibilityProductHighlights,
    setButtonVisibilityProductHighlights,
  ] = useState(true);

  const [
    buttonVisibilityTypicalApplications,
    setButtonVisibilityTypicalApplications,
  ] = useState(true);

  const productHighlightsHTMLContent = product_highlights_detail
    ? makeParagraph(product_highlights_detail)
    : null;
  const hiddenPartRegProductHighlights = regex.exec(
    productHighlightsHTMLContent,
  );
  const hiddenPartProductHighlights = hiddenPartRegProductHighlights
    ? hiddenPartRegProductHighlights?.[4]
    : "";

  const buttonProductHighlights = buttonRegex.exec(
    productHighlightsHTMLContent,
  );
  const replacedProductHighlights = productHighlightsHTMLContent
    ? productHighlightsHTMLContent
        .replace(hiddenPartProductHighlights, "")
        .replace(buttonProductHighlights ? buttonProductHighlights[0] : "", "")
    : null;
  const [productHighlights, setProductHighlights] = useState(
    replacedProductHighlights,
  );

  const TypicalApplicationsHTMLContent = typical_applications
    ? makeParagraph(typical_applications)
    : null;
  const hiddenPartRegTypicalApplications = regex.exec(
    TypicalApplicationsHTMLContent,
  );
  const hiddenPartTypicalApplications = hiddenPartRegTypicalApplications
    ? hiddenPartRegTypicalApplications?.[4]
    : "";

  const buttonTypicalApplications = buttonRegex.exec(
    TypicalApplicationsHTMLContent,
  );
  const replacedTypicalApplications = typical_applications
    ? TypicalApplicationsHTMLContent.replace(
        hiddenPartTypicalApplications,
        "",
      ).replace(
        buttonTypicalApplications ? buttonTypicalApplications[0] : "",
        "",
      )
    : null;
  const [typicalApplications, setTypicalApplications] = useState(
    replacedTypicalApplications,
  );

  useEffect(() => {
    setProductDescription(replacedDescriptionText);
    setTypicalApplications(replacedTypicalApplications);
    setProductHighlights(replacedProductHighlights);
  }, [
    replacedDescriptionText,
    replacedTypicalApplications,
    replacedProductHighlights,
  ]);

  if (is_discontinued) {
    return (
      <DiscontinuedWrapper
        recommended_successor_kp={recommended_successor_kp}
        eye_catcher={eye_catcher}
        tb={tb}
      />
    );
  } else {
    return (
      <div className="overview">
        <div
          className="overview-description"
          dangerouslySetInnerHTML={{
            __html: sanitize(productDescription),
          }}
        />
        {buttonVisibility && hiddenPart && (
          <Button
            variant={"primary-outline"}
            icon="plus"
            label={showMoreButtonAction.label}
            onClick={() => {
              setProductDescription(productDescription + hiddenPart);
              setButtonVisibility(false);
            }}
            className="mb-2"
          />
        )}
        {related_images?.length > 0 && (
          <Slider
            className="pl-0 pl-lg-0 my-3 overview-slider"
            fixHeightMode={250}
            navigation="outer"
            spaceBetween={15}
            forceSliderForSingleSlides
          >
            {related_images.map((image) => (
              <RelatedImageSlide key={image.id} image={image} />
            ))}
          </Slider>
        )}
        {(productHighlights || typicalApplications) && (
          <Tabs
            defaultTabIndex={productHighlightsHTMLContent ? 0 : 1}
            alignment="left"
            tabs={[
              ...(productHighlights
                ? [
                    {
                      title:
                        variant === "so"
                          ? overviewHighlightsLabel?.label
                          : productHighlightsLabel?.label ??
                            "Product highlights",
                      component: (
                        <>
                          {variant !== "so" ? (
                            <p className="small mb-2">
                              {productHightlightLabel.label}
                            </p>
                          ) : null}

                          <div
                            className="styled-list-wrapper"
                            dangerouslySetInnerHTML={{
                              __html: sanitize(productHighlights),
                            }}
                          />
                          {buttonVisibilityProductHighlights &&
                          hiddenPartProductHighlights ? (
                            <Button
                              variant={"primary-outline"}
                              icon="plus"
                              label={showMoreButtonAction.label}
                              onClick={() => {
                                setProductHighlights(
                                  productHighlights +
                                    hiddenPartProductHighlights,
                                );
                                setButtonVisibilityProductHighlights(false);
                              }}
                              className="mb-2"
                            />
                          ) : null}
                        </>
                      ),
                    },
                  ]
                : []),
              ...(typicalApplications
                ? [
                    {
                      title:
                        typicalApplicationsLabel?.label ??
                        "Typical applications",
                      component: (
                        <>
                          <div
                            className="styled-list-wrapper"
                            dangerouslySetInnerHTML={{
                              __html: sanitize(typicalApplications),
                            }}
                          />
                          {buttonVisibilityTypicalApplications &&
                          hiddenPartTypicalApplications ? (
                            <Button
                              variant={"primary-outline"}
                              icon="plus"
                              label={showMoreButtonAction.label}
                              onClick={() => {
                                setTypicalApplications(
                                  typicalApplications +
                                    hiddenPartTypicalApplications,
                                );
                                setButtonVisibilityTypicalApplications(false);
                              }}
                              className="mb-2"
                            />
                          ) : null}
                        </>
                      ),
                    },
                  ]
                : []),
            ]}
          />
        )}
      </div>
    );
  }
};

export const ProductMedia: FunctionComponent<
  DeepPartial<ProductDetailProps>
> = ({ entity }) => {
  return (
    entity.related_media?.length > 0 && (
      <>
        <Row className={`entity-grid`}>
          {entity.related_media.map((row) => {
            return (
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={3}
                className={`mb-4`}
                key={row.id}
              >
                <Card
                  cardImg={{
                    media: row as CloudinaryProps,
                    background: "grey-triangle",
                    ar: "ar169",
                  }}
                  title={row.title}
                  variant={"vertical"}
                  // @ts-ignore
                  lightbox={row.__typename === "Im" ? "image" : "video"}
                ></Card>
              </Col>
            );
          })}
        </Row>
      </>
    )
  );
};

export const ProductLinks: FunctionComponent<
  DeepPartial<ProductDetailProps>
> = ({ entity, li }) => {
  return entity.related_link?.length > 0 || li?.length > 0 ? (
    <>
      <div className="d-block">
        {entity.related_link.length > 0 && (
          <div className="slider__navigation-hover-container">
            <div className="container mb-4">
              <RelatedProductsSlider
                related={entity.related_link as Li[]}
                background="none"
                ar={null}
              />
            </div>
          </div>
        )}
        {li && (
          <div className="slider__navigation-hover-container">
            <div className="container mb-4">
              <RelatedProductsSlider
                related={li as Li[]}
                background="none"
                groupBy={"title"}
                selectBy={"link_target_language"}
                ar={null}
              />
            </div>
          </div>
        )}
      </div>
    </>
  ) : null;
};

export const ProductAccessories: FunctionComponent<ProductDetailProps> = (
  props,
) => {
  const [kpButtonAction] = useLabels([
    entitiesConfig.kp.call_to_action_button._default,
    "Learn more",
  ]);
  return props.entity.accessories_for_this_product?.length > 0 ? (
    <>
      <Row className={`entity-grid`}>
        {props.entity.accessories_for_this_product.map(
          (row: ResolvedUrl<Kp>) => {
            const url = row._url;

            return (
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                className={`mb-4`}
                key={row.id}
              >
                <Card
                  title={row.title}
                  cardLabel={
                    // @ts-ignore
                    row?.eye_catcher?.length > 0 && {
                      // @ts-ignore
                      title: row.eye_catcher[0].label,
                      variant: "secondary",
                      position: "left",
                    }
                  }
                  featuredLogos={
                    Boolean(row.featured_logos?.length) &&
                    row.featured_logos?.slice(0, 2).map((featuredLogo) => ({
                      media: featuredLogo,
                      width: 80,
                      height: 60, // TODO: auto height
                    }))
                  }
                  actionButtons={
                    url && [
                      {
                        label: kpButtonAction?.label,
                        url,
                        variant: "primary",
                      },
                    ]
                  }
                  cardImg={
                    row?.key_visual?.length > 0 &&
                    (row.key_visual?.[0].__typename === "Im" ||
                      row.key_visual?.[0].__typename === "Vi")
                      ? {
                          media: row.key_visual?.[0],
                          ar: "ar43",
                          background: "grey-triangle",
                          width: 410,
                          height: 307,
                        }
                      : undefined
                  }
                  variant="vertical"
                  url={url}
                >
                  <div>
                    <p
                      className="h6"
                      dangerouslySetInnerHTML={{
                        __html: sanitize(unescape(row.subtitle)),
                      }}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitize(unescape(row.excerpt)),
                      }}
                    ></div>
                  </div>
                </Card>
              </Col>
            );
          },
        )}
      </Row>
    </>
  ) : null;
};

export const ProductApplications: FunctionComponent<
  Partial<ProductDetailProps>
> = (props) => {
  const { language } = useLocale();
  const [kaButtonAction] = useLabels([
    entitiesConfig.ka.call_to_action_button._default,
    "Learn more",
  ]);
  return props.ka?.length > 0 ? (
    <>
      <Row className={`entity-grid`}>
        {props.ka.map((row: ResolvedUrl<Ka>) => {
          const url = row._url;
          return (
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={3}
              className={`mb-4`}
              key={row.id}
            >
              <Card
                title={row.title}
                cardLabel={
                  "eye_catcher" in row &&
                  Array.isArray(row.eye_catcher) &&
                  row?.eye_catcher?.length > 0 && {
                    title: row.eye_catcher[0].label,
                    variant: "secondary",
                    position: "left",
                  }
                }
                featuredLogos={
                  "featured_logos" in row &&
                  Array.isArray(row.featured_logos) &&
                  row.featured_logos?.length > 0 &&
                  row.featured_logos.slice(0, 2).map((featuredLogo) => ({
                    media: featuredLogo,
                  }))
                }
                actionButtons={
                  url && [
                    {
                      label: kaButtonAction?.title,
                      url,
                      variant: "primary",
                    },
                  ]
                }
                cardImg={
                  row?.key_visual?.length > 0 &&
                  (row.key_visual?.[0].__typename === "Im" ||
                    row.key_visual?.[0].__typename === "Vi")
                    ? {
                        media: row.key_visual?.[0],
                        ar: "ar43",
                        background: "grey-triangle",
                        width: 300,
                        height: 225,
                      }
                    : undefined
                }
                onlyEnglish={row._lang !== language}
                variant="vertical"
                url={url}
              >
                <div>
                  <p className="h6">{unescape(row.subtitle)}</p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitize(unescape(row.excerpt)),
                    }}
                  ></div>
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  ) : null;
};

export const ProductVideoDownloads: FunctionComponent<
  ProductDetailDownloadProps
> = (videoDownloads) => {
  return <ContentSet {...videoDownloads} skeleton={true} />;
};

export const ProductDownloads: FunctionComponent<ProductDetailDownloadProps> = (
  downloads,
) => {
  return <ContentSet {...downloads} skeleton={true} />;
};
